const env = process.env.NEXT_PUBLIC_ENV

const config = {
  test: {
    api: 'https://zcapi.natapp4.cc'
  },
  prod: {
    // api: 'https://app.ymedium.top'
     api: 'https://app.ymedium.top'
  }
}

export default config[env]