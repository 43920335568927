import styles from './index.module.scss'
import { useRecoilValue } from 'recoil'
import { userInfo } from '@/store'
import { useUserTab, useShowLogOut, useShowLogin } from '@/utils/hooks'
import cm from 'classnames'
import Button from '../../../button'
import { useMemo } from 'react'
import dayjs from 'dayjs'

const Index = () => {
  const setShowLogOut = useShowLogOut()
  const setShowLoginIn = useShowLogin()
  const user = useRecoilValue(userInfo)
  const tabArr = useUserTab()

  const quanyiMemo = useMemo(() => {
    const expire = dayjs(user.expireTime * 1000)
    const lastTime = expire.diff(dayjs(new Date()), 'day')
    if (lastTime >= 0 && lastTime <= 2) {
      return {
        img: (
          <div className={cm(styles.vipImg, styles.vipActive)}>
            <img loading="lazy" className={styles.vipLogo} src="/api/images/vip-active" alt="vip" />
          </div>
        ),
        time: <p className={cm(styles.pActive,'textEllipsis')}>VIP权益还有{lastTime + 1}天到期</p>,
        toVip: (
          <Button hover="opacity" link href="/vip" className={styles.toVip}>
            立即续费
          </Button>
        )
      }
    } else if (lastTime > 2) {
      return {
        img: (
          <div className={cm(styles.vipImg, styles.vipActive)}>
            <img loading="lazy" className={styles.vipLogo} src="/api/images/vip-active" alt="vip" />
          </div>
        ),
        time: <p className={cm(styles.pActive,'textEllipsis')}>VIP权益至{expire.format('YYYY/MM/DD')}</p>
      }
    } else {
      if (!user.expireTime) {
        return {
          img: <div className={styles.vipImg}></div>,
          time: null,
          toVip: (
            <Button hover="opacity" link href="/vip" className={styles.toVip}>
              开通VIP
            </Button>
          )
        }
      }
      return {
        img: (
          <div className={cm(styles.vipImg, styles.vipInActive)}>
            <img loading="lazy" className={styles.vipLogo} src="/api/images/vip-inactive" alt="vip" />
          </div>
        ),
        time: <p className={styles.pInActive}>VIP权益已过期</p>,
        toVip: (
          <Button hover="opacity" link href="/vip" className={styles.toVip}>
            开通VIP
          </Button>
        )
      }
    }
  }, [user])

  return (
    <>
      {user?.isLogin ? (
        <div className={styles.loginUser}>
          <img loading="lazy" alt="用户" src="/api/images/user3" className={styles.head} onClick={tabArr[0].event} />
          <span className={cm(styles.name, 'textEllipsis')}>{user?.email}</span>
          <div className={styles.loginMenu}>
            <div className={styles.userInfo}>
              {quanyiMemo.img}
              <div className={cm(styles.vipDate,quanyiMemo.toVip ? styles.ellipsis : styles.full)}>
                <p className={cm(styles.email, 'textEllipsis')}>{user?.email}</p>
                {quanyiMemo.time}
              </div>
              {quanyiMemo.toVip}
            </div>
            <div className={styles.userTabMenu}>
              {tabArr.map((item, index) => {
                return (
                  <div className={styles.tabMenuItems} key={index} onClick={item.event}>
                    <img loading="lazy" src={item.icon} alt={item.label} />
                    <span>{item.label}</span>
                  </div>
                )
              })}
            </div>
            <div className={styles.exitText} onClick={setShowLogOut}>
              退出登录
            </div>
          </div>
        </div>
      ) : null}
      {user?.isLogin ? null : (
        <Button hover="opacity" className={styles.notLogin} onClick={() => setShowLoginIn(true)}>
           <img loading="lazy" src="/api/images/user" />
          登录 
        </Button>
      )}
    </>
  )
}

export default Index
