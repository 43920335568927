import styles from './index.module.scss'
import UserLoginBox from './user-login-box'
import SearchInput from './search-input'
import cm from 'classnames'
import Button from '../../button'
import Link from 'next/link'
import { userInfo, h5DownloadModal } from '@/store'
import { useShowLogin, useVipDialog } from '@/utils/hooks'
import { buyVipReport, openPayModalReport } from '@/utils/report'
import { useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'

const Index = ({vipData}) => {

  const setShowLogin = useShowLogin()
  const showVipDialog = useVipDialog()
  const [currIndex, setCurrIndex] = useState(0)
  // const { vipData } = props

  // const vipData =  vipData
  const user = useRecoilValue(userInfo)
  // console.log("===== vipData =====",vipData)
  const openDialog = index => {
    const isLogin = localStorage.getItem('token')
    openPayModalReport({
      isLogin: isLogin ? 'true' : 'false',
      package_type: vipData[index].pDesc,
      user_type: user?.isVip ? '会员' : '非会员'
    })
    if (!isLogin) {
      setShowLogin(true)
      return
    }
    buyVipReport({
      package_type: vipData[index].pDesc,
      user_type: user.isVip ? '会员' : '非会员'
    })
    showVipDialog(index)
  }

  const quanyiArr = [
    {
      icon: '/api/images/v-line.webp',
      title: '最优线路',
      desc: '会员独享高清线路，点播资源秒开无卡顿。画质更细腻，观看体验瞬间提升！'
    },
    {
      icon: '/api/images/v-live.webp',
      title: '电视直播',
      desc: '会员畅享流畅电视直播，500+频道任你选。直击热门节目，精彩不断，娱乐生活全方位升级！'
    },
    {
      icon: '/api/images/v-source.webp',
      title: '资源下载',
      desc: '随时随地的下载视频，然后随时随地观看，观影不受网络限制。留住精彩时刻，随时随地畅享！'
    },
    {
      icon: '/api/images/v-rate.webp',
      title: '倍速播放',
      desc: '视频节奏由我掌控！会员独享全部倍速，观看更自由，随心调节观影体验！'
    }
  ]

  return (
    <div className={cm(styles.rightMenu)}>
      <SearchInput />
      <Button hover="scaleImg"  className={cm(styles.rightBtn, styles.vip)}>

        <Link link href="/vip">
            <img loading="lazy" src="/api/images/vip2.png" title="会员" alt="会员" />
            <div>会员</div>
        </Link>
       
        <div className={styles.btnVip}>

            <div className={styles.noticeCon}>
                {
                  quanyiArr.map((item,index)=>{
                    return (
                      <>
                       <div className={styles.item}>
                          <img src={item.icon}  />
                          <div>{item.title}</div>
                       </div>
                      </>
                    )
                  })
                }
            </div>

            <div className={styles.joinVip}>
                <div  className={styles.btn}  onClick={() => openDialog(0)}>
                    加入会员
                </div>
            </div>

        </div>
      </Button>
      <Button
        rel="nofollow"
        hover="scaleImg"
        link
        target="_blank"
        href="https://t.me/+CKkE1hC7_3dlYTk1"
        className={cm(styles.rightBtn, styles.join)}
      >
        <img loading="lazy" src="/api/images/join" alt="加入群聊" title="加入群聊" />
        <div>群聊</div>
        {/* <div className={styles.joinClub}>
          <span>加入群聊</span>
          <img loading="lazy" src="/api/images/ar" alt="群聊" />
        </div> */}
      </Button>
      <Button hover="scaleImg" link href="/download" className={cm(styles.rightBtn, styles.download)}>
        <img loading="lazy" src="/api/images/download_client" title="下载" alt="下载" />
        {/* <div className={styles.btnDownload}></div> */}
        <div>下载</div>
      </Button>

      <Button hover="scaleImg" link href="/history" className={cm(styles.rightBtn, styles.download)}>
        <img loading="lazy" src="/api/images/history"  title="历史" alt="历史" />
        <div>历史</div>
        {/* <div className={styles.btnScore}>
          <span>积分兑会员</span>
          <img loading="lazy" src="/api/images/ar" alt="群聊" />
        </div> */}
      </Button>

      {/* 信息框 */}
      <UserLoginBox />
    </div>
  )
}

export default Index
