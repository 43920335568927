import '@/styles/global.scss'
import '@/styles/responsive.scss'
import '@/styles/history-media.scss'
import Head from 'next/head'
import { useRouter } from 'next/router'
import Container from '@c/container'
import { RecoilRoot } from 'recoil'
import { getVipData } from '@/api'
import { openVConsole } from '@/utils/utils'
import { useEffect } from 'react'

const formatCanonical = url => {
  let str = url.replace('play-thrid', 'play-official')
  try {
    str = str.split('?')[0]
  } catch (error) {}
  return str
}

const App = ({ Component, pageProps, vipData }) => {
  const isTestWeb = process.env.NEXT_PUBLIC_BAN_CONSOLE != '1'
  let { asPath } = useRouter()
  const { title, desc } = pageProps.layoutProps
  useEffect(() => {
    if (isTestWeb) {
      openVConsole()
    }
  }, [])
  return (
    <RecoilRoot>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
        <link rel="icon" href="/favicon.ico" />
        {isTestWeb ? <meta name="robots" content="noindex" /> : null}
        {pageProps.layoutProps.noSomeMetaInfo ? null : (
          <>
            <title>{title}</title>
            <meta name="description" content={desc} />
            <link rel="canonical" href={'https://hlove.tv' + formatCanonical(asPath)} />
          </>
        )}
      </Head>
      <Container Component={Component} pageProps={{ ...pageProps, vipData }} />
    </RecoilRoot>
  )
}

App.getInitialProps = async () => {
  const res = await getVipData()
  return {
    vipData: res?.data?.products || []
  }
}
export default App
