import request from '@/services/request'

// 获取搜索结果
export const getSearchData = async (data, options = {}) => {
  return await request.post('/v3/web/api/search', data, options)
}

// 获取首页数据
export const getPageData = async (type, options = {}) => {

  const result =  await request.get(`/v3/web/api/home?chName=${type}`, {}, options)
  console.log("=====  获取首页数据 ====",result)
  return  result
}

// 获取搜索top数据
export const getTopData = async () => {
  return await request.get('/v3/web/api/getSearchOptions')
}
