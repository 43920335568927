import request from '@/services/request'

// 获取搜索筛选项
export const getFilterCondition = async (params, options = {}) => {
  return await request.get('/v3/web/api/getFilterCondition', params, options)
}

/**
 * country
 * label
 * chName
 * startTime
 * endTime
 * page
 * pageSize
 * */
///web/api/filter
export const getFilterData = async (data, options = {}) => {
  console.log(data,options)
  return await request.post('/v3/web/api/filter', data, options)
}
